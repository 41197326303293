import { Example } from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {
        text: 'Is the funding scheme "Seed Fund for Basic Research for New Staff" open for application now?',
        value: 'Is the funding scheme "Seed Fund for Basic Research for New Staff" open for application now?'
    },
    { text: "Which funding schemes are currently open for application?", value: "Which funding schemes are currently open for application?" },
    {
        text: 'Who should I contact for information on "Germany/Hong Kong Joint Research Scheme"?',
        value: 'Who should I contact for information on "Germany/Hong Kong Joint Research Scheme"?'
    },
    {
        text: "Please find the funding schemes with funding amount over $10,000",
        value: "Please find the funding schemes with funding amount over $10,000"
    }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {EXAMPLES.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
